<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Quản lý BlackList Facebook</h4>

            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Quản lý BlackList Facebook</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row">
                <form @submit.prevent="getBlackList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo Fb Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập fb id"
                      v-model="fb_id"
                    />
                  </div>
                </form>
                <form @submit.prevent="getBlackList" class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Tìm theo User Id</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="nhập user id"
                      v-model="user_id"
                    />
                  </div>
                </form>
                <form class="col-md-4 mb-4 mb-md-0">
                  <div class="form-group">
                    <label for="">Limit</label>
                    <select class="form-select" v-model="limit" @change="getBlackList">
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="1000">1000</option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <loading-vue v-if="loading" />
      <div class="row">
        <div class="col-md-12">
          <datatable
            :stt="true"
            :title="'Danh sách BlackList Facebook'"
            :columns="columns"
            :rows="manipulatedList"
            :defaultPerPage="10"
          >
            <th slot="thead-tr-o" class="text-center" style="vertical-align: middle">Thao Tác</th>
            <td slot="tbody-tr-o" class="text-center" slot-scope="props">
              <button
                class="btn btn-danger font-10 py-1 btn-xs"
                @click="removeAccountFromBlackList(props.row)"
              >
                Xóa
              </button>
            </td>
          </datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import Datatable from "@/components/datatable/Datatable.vue";
import LoadingVue from "@/components/loading/Loading.vue";
import { buildParamQuery, catchError, formatNumber } from "@/helpers/index";
import blackListFbApi from "@/api/blacklist-facebook";

export default {
  name: "BlackListFacebook",
  components: {
    Datatable,
    LoadingVue,
  },
  data() {
    return {
      username: null,
      fb_id: null,
      user_id: null,
      limit: 50,
      list: [],
      loading: false,
      columns: [
        { label: "Id", field: "id" },
        { label: "User Id", field: "user_id" },
        { label: "Fb Id", field: "fb_id", fb_link: true },
        { label: "Ngày tạo", field: "created_at", timeago: true },
        { label: "Ngày cập nhật", field: "updated_at", timeago: true },
      ],
    };
  },
  computed: {
    manipulatedList() {
      return this.list;
    },
  },
  created() {
    this.getBlackList();
  },
  methods: {
    async getBlackList() {
      const params = {
        limit: this.limit,
        fb_id: this.fb_id,
        user_id: this.user_id,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await blackListFbApi.list(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.list = response.data || [];
        } else {
          this.$toastr.error(catchError(response));
        }
      } else {
        this.$toastr.error("Tải black list không thành công !");
      }
    },
    async removeAccountFromBlackList(account) {
      const params = {
        fb_id: account.fb_id,
      };
      this.loading = true;
      const response = await blackListFbApi.remove(params);
      this.loading = false;
      if (response?.success) {
        this.$toastr.success(response?.message || "Xoá thành công !");
        this.list = this.list.filter((acc) => {
          return acc.fb_id !== account.fb_id;
        });
      } else {
        this.$toastr.error(catchError(response));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
